import React,{useState} from 'react';
import Button from 'react-bootstrap/Button'
import Section from '../../../HOC/Section';
import Modal from 'react-bootstrap/Modal'
import blogImage1 from '../../../assets/img/blog1.jpg';
import blogImage2 from '../../../assets/img/blog2.jpg';
import blogImage3 from '../../../assets/img/blog3.jpg';

const Blog = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <Section id='blog'>
      <div className='container pt-2 pb-5'>
        <div className='section-header pt-5 pb-5 text-center'>
          <h3 className='section-title'>
            <span>Our </span>Blog
          </h3>
          <h6 className='section-subtitle mr-auto ml-auto'>
          Follow the Vlight blog to find out about the latest digital trends
          </h6>
        </div>
        <div className='section-content'>
          <div className='row'>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={blogImage1} className='card-img-top' alt='Blog 1' />
                <div className='card-body'>
                  <h5 className='card-title'>High Quality Support</h5>
                  <p className='card-text'>
                  Our thoughts on customer support, product updates, and more ...
                  </p>
                  {/* <a href='#!' className='btn btn-sm btn-primary'>
                    Read More
                  </a> */}
                </div>
              </div>
            </div>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={blogImage2} className='card-img-top' alt='Blog 2' />
                <div className='card-body'>
                  <h5 className='card-title'>vlightsoft Team</h5>
                  <p className='card-text'>
                  Our blog on latest technology, recruiting, and people...
                  </p>
                  {/* <a href='#!' className='btn btn-sm btn-primary'>
                    Read More
                  </a> */}
                </div>
              </div>
            </div>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={blogImage3} className='card-img-top' alt='Blog 3' />
                <div className='card-body'>
                  <h5 className='card-title'>Business Deal</h5>
                  <p className='card-text'>
                  Articles on sales and revenue growth with better CRM decisions.
                  </p>
                  {/* <a href='#!' className='btn btn-sm btn-primary' >
                   onClick={handleShow} */}
                  {/* Read More */}
                  {/* </a> */} 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Modal title</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          I will not close if you click outside me. Don't even try to press
          escape key.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Section>
  );
};

export default Blog;
