import React from 'react';

import Link from '../Link/Link';

const footer = () => {
  return (
    <footer className=''>
      <div className='container text-light pt-5'>
        <div className='row'>
          <div className='col-sm-6 col-md-6 col-lg-4 mb-5'>
            <div className='footer-title'>
              <h6>About Us</h6>
            </div>
            <div className='footer-content'>
              <p>
                <small className='text-muted'>
                Established in 2021, vlightsoft Technologies is an ISO 9001:2008 Certified Company which has ripened to become a Global service provider of Software and App development, catering all domains specifically Software, BFSI, Telecom, Online Media, IT, Manufacturing, Power, Healthcare, IOT, Logistics, Sports...
                </small>
              </p>
              <button className='btn btn-sm btn-primary rounded-0'>
              <Link target='about'>Learn More</Link>
              </button>
            </div>
          </div>
          <div className='col-sm-6 col-md-6 col-lg-2 mb-5'>
            <div className='footer-title'>
              <h6>Quick Links</h6>
            </div>
            <div className='footer-content'>
              <ul className='list-group quick-links'>
                <li>
                  <Link target='home' offset={-120}>
                    Home
                  </Link>
                </li>
                <li>
                  <Link target='about'>About</Link>
                </li>
                <li>
                  <Link target='services'>Services</Link>
                </li>
                <li>
                  <Link target='blog'>Blog</Link>
                </li>
                <li>
                  <Link target='contact'>Contact</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className='col-sm-6 col-md-6 col-lg-3 mb-5'>
            <div className='footer-title'>
              <h6>Latest News</h6>
            </div>
            <div className='footer-content'>
              <p>
                <small className='text-muted'>
                Toyota Buys Software Firm Renovo To Accelerate Self-Driving Tech Development. ...
                </small>
              </p>
              <p>
                <small className='text-muted'>
                  China's Geely Starts Making Commercial Satellites. ...
                </small>
              </p>
              <p>
                <small className='text-muted'>Oppo Cuts 20 Percent of Staff After Going Up Against Apple. ...</small>
              </p>
              <p>
                <small className='text-muted'>
General Motors Invests In Oculii, Radar Software Maker For Self-Driving Cars.</small>
              </p>
            </div>
          </div>
          <div className='col-sm-6 col-md-6 col-lg-3 mb-5'>
            <div className='footer-title'>
              <h6>Contact Us</h6>
            </div>
            <div className='footer-content'>
              <p className='text-muted'>
                <small>Address : katcheri street, Palayakayal, Thoothukudi</small>
              </p>
              <p className='text-muted'>
                <small>Phone : +91 9566412482</small>
              </p>
              <p className='text-muted'>
                <small>E-mail : support@vlightsoft.com</small>
              </p>
              <div className='social-media mt-4'>
                <a href='!#' className='text-light'>
                  <i className='fab fa-facebook-f mr-4' />
                </a>
                <a href='!#' className='text-light'>
                  <i className='fab fa-twitter mr-4' />
                </a>
                <a href='!#' className='text-light'>
                  <i className='fab fa-instagram mr-4' />
                </a>
                {/* <a href='!#' className='text-light'>
                  <i className='fab fa-github' />
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='bottom-footer pt-3 pb-3 text-center'>
        <small>© 2021, Vlight Software Technologies. Ltd. All Rights Reserved.</small>
      </div>
    </footer>
  );
};

export default footer;
