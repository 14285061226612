import React from 'react';

import Section from '../../../HOC/Section';

const Service = () => {
  return (
    <Section id='services'>
      <div className='container pt-2 pb-5'>
        <div className='section-header pt-5 pb-5 text-center'>
          <h3 className='section-title'>
            <span>Our </span>Services
          </h3>
          <h6 className='section-subtitle mr-auto ml-auto'>
          Our clientele comprises of Fortune 200 companies as well as Startups and Small and Middle-level enterprises.
          </h6>
        </div>
        <div className='section-content'>
          <div className='row'>
            <div className='col-md-6 col-lg-4 mb-3'>
              <div className='service-box d-flex'>
                <div className='service-icon mr-4'>
                  <i className='fas fa-briefcase' />
                </div>
                <div className='service-body'>
                  <h5 className='service-title'>Business Planning</h5>
                  <p className='service-description'>
                    We will help you on archiving business idea and all the relevant internal and external elements involved in launching a new venture
                  </p>
                </div>
              </div>
            </div>
            <div className='col-md-6 col-lg-4 mb-3'>
              <div className='service-box d-flex'>
                <div className='service-icon mr-4'>
                  <i className='fas fa-chart-bar' />
                </div>
                <div className='service-body'>
                  <h5 className='service-title'>Product Marketing</h5>
                  <p className='service-description'>
                    Our team will help you to bridges the gap between product management and marketing communications
                  </p>
                </div>
              </div>
            </div>
            <div className='col-md-6 col-lg-4 mb-3'>
              <div className='service-box d-flex'>
                <div className='service-icon mr-4'>
                  <i className='fas fa-fist-raised' />
                </div>
                <div className='service-body'>
                  <h5 className='service-title'>Risk Management</h5>
                  <p className='service-description'>
                    We provide you the solution for identification, analysis, and acceptance or mitigation of uncertainty in investment decisions
                  </p>
                </div>
              </div>
            </div>
            <div className='col-md-6 col-lg-4 mb-3'>
              <div className='service-box d-flex'>
                <div className='service-icon mr-4'>
                  <i className='far fa-paper-plane' />
                </div>
                <div className='service-body'>
                  <h5 className='service-title'>Custom Applications Development</h5>
                  <p className='service-description'>
                  Our development team will design & Develop the software applications for your specific needs, Company needs or group of users within an organization.
                  </p>
                </div>
              </div>
            </div>
            <div className='col-md-6 col-lg-4 mb-3'>
              <div className='service-box d-flex'>
                <div className='service-icon mr-4'>
                  <i className='fas fa-gem' />
                </div>
                <div className='service-body'>
                  <h5 className='service-title'>Integration/Migration</h5>
                  <p className='service-description'>
                    Our Development team will help you to archive your goals on integrating/Migrate the data in the cloud APPs.
                    </p>
                    <ul className='service-description'>
  <li>Zoho</li>
  <li>SalesForce</li>
  <li>Netsuite</li>
</ul>
                  
                  
 
                </div>
              </div>
            </div>
            <div className='col-md-6 col-lg-4 mb-3'>
              <div className='service-box d-flex'>
                <div className='service-icon mr-4'>
                  <i className='far fa-life-ring' />
                </div>
                <div className='service-body'>
                  <h5 className='service-title'>Support Team</h5>
                  <p className='service-description'>
                  Our Support team will  assist and connect you with the whole department group in meeting of your company's goals and exceeds your performance expectations.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Service;
