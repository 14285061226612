import React from 'react';

import Section from '../../../HOC/Section';

import blogImage1 from '../../../assets/img/prasad.jpg';
import blogImage2 from '../../../assets/img/naveen.jpg';
import blogImage3 from '../../../assets/img/lucky.jpg';

import Carousel from 'react-material-ui-carousel'
import { Paper, Button } from '@material-ui/core'

function Clients(props)
{
    var items = [
        {
            Company1:"Farming Technologies",
            name1: " Narendra Prasad (CEO)",
            description1: "My project was very well finished. Great works!!",
            image1:blogImage1,
            Company2:"S.A.L.T",
            name2: "Naveen (President)",
            image2:blogImage2,
            description2: "For every projects they durprised me with their great skill sets..",
            Company3:"Lucky Tech",
            name3: " Lucky (SEO)",
            description3: "They exceeded my expectation every time I add an project.",
            image3:blogImage3
        },
        {
            Company1:"Farming Technologies1",
            name1: " Narendra Prasad (CEO)",
            description1: "My project was very well finished. Great works!!",
            image1:blogImage1,
            Company2:"S.A.L.T",
            name2: "Naveen (President)1",
            image2:blogImage2,
            description2: "For every projects they durprised me with their great skill sets..",
            Company3:"Lucky Tech",
            name3: " Lucky (SEO)1",
            description3: "They exceeded my expectation every time I add an project.",
            image3:blogImage3
        }
       
    ]

    return (
        <Section id='Clients'>
        <div className='container pt-2 pb-5'>
          <div className='section-header pt-5 pb-5 text-center'>
            <h3 className='section-title'>
              <span>Our </span>Clients
            </h3>
            <h6 className='section-subtitle mr-auto ml-auto'>
               Our client list speaks for itself. Since our earliest days, we’ve represented everything from start-ups to Fortune 500 companies with that same partnership approach and dedication at the core of every engagement. Every day, since 2021, we’ve been devoting our hearts and minds to our clients, achieving great successes and building lasting relationships. 
               Here is a sample of our current and past clients
            </h6>
          
          </div>
        <Carousel animation='slide'>
            
            {
                items.map( (item, i) =>  <Item key={i} item={item}></Item> )
            }
        </Carousel>
        </div>
        </Section>
    )
}

function Item(props)
{
    return (
   
        <Paper>
          

            <div className='section-content'>
          <div className='row'>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={props.item.image1} className='card-img-top' alt='Blog 1' />
                <div className='card-body'>
                  <h5 className='card-title'>{props.item.company1}</h5>
                  <p className='card-Subtitle'>
                  {props.item.name1}
                  </p>
                  <p className='card-text'>
                  {props.item.description1}
                  
                  </p>
                  {/* <a href='#!' className='btn btn-sm btn-primary'>
                    Read More
                  </a> */}
                </div>
              </div>
            </div>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={props.item.image2} className='card-img-top' alt='Blog 2' />
                <div className='card-body'>
                  <h5 className='card-title'>{props.item.company2}</h5>
                  <p className='card-Subtitle'>
                  {props.item.name2}
                  </p>
                  <p className='card-text'>
                  {props.item.description2}
                  </p>
                  {/* <a href='#!' className='btn btn-sm btn-primary'>
                    Read More
                  </a> */}
                </div>
              </div>
            </div>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={props.item.image3} className='card-img-top' alt='Blog 3' />
                <div className='card-body'>
                  <h5 className='card-title'>{props.item.company3}</h5>
                  <p className='card-Subtitle'>
                  {props.item.name3}
                  </p>
                  <p className='card-text'>
                  {props.item.description3}
                  </p>
                  {/* <a href='#!' className='btn btn-sm btn-primary'>
                    Read More
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        </Paper>
        
    )
}

export default Clients;
