import React from 'react';

import Section from '../../../HOC/Section';
import aboutImage from '../../../assets/img/about.jpg';

const about = () => {
  return (
    <Section id='about'>
      <div className='container pt-2 pb-5'>
        <div className='section-header pt-5 pb-5 text-center'>
          <h3 className='section-title'>
            <span>Our </span>Company
          </h3>
          <h6 className='section-subtitle mr-auto ml-auto'>
          A preferred IT Outsourcing & Solutioning partner of Hundreds of happy clients in the USA, UK, Australia, Asia Pacific and across the globe. WE WORK ON YOUR IDEA, TO MAKE YOUR IDEA WORK!.
          </h6>
        </div>
        <div className='section-content'>
          <div className='row'>
            <div className='col-md-12 col-lg-6 mb-3'>
              <div className='aboutImage'>
                <img src={aboutImage} alt='about company' />
              </div>
            </div>
            <div className='col-md-12 col-lg-6'>
              <h3 className='about-title'>About us</h3>
              <div className='about-description'>
                <p>
                Established in 2021, vlightsoft Technologies is a California based Global IT Solution provider focused in ERP, CRM, Data Integration, web and mobile space with active business operations across US, Singapore and India.


                </p>
                <p>
                We practice a unique engagement model with our customers; which starts at the product ideation phase till maintenance where our services span from
                </p>
<ul>
  <li>Product Discovery and Consulting</li>
  <li>Project plans and Minimal Viable Product Development</li>
  <li>Application Development, Management</li>
  <li>Packaged Application Services</li>
</ul>
               
             <p>
             Verification and Testing followed by after-sales support and creating product marketing strategies and promotional activities. We are a name in the Software Development & Digital Marketing segment.
               </p>
                {/* <button className='btn btn-primary rounded-0'>Read More</button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default about;
