import React,{useState} from 'react';

import Section from '../../../HOC/Section';
import apicall from '../../UI/JS/allapicall.js';
const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(true);
  return (
    <Section id='contact'>
      <div className='container pt-2 pb-5'>
        <div className='section-header pt-5 pb-5 text-center'>
          <h3 className='section-title'>
            <span>Get in touch with </span>Us
          </h3>
          <h6 className='section-subtitle mr-auto ml-auto'>
           We are here with you 
          </h6>
        </div>
        
        <div className='section-content'>
          <div className='row'>
            <div className='col-md-9 col-lg-7 mr-auto ml-auto'>
              {/* <form> */}
                <div className='form-group'>
                  <input
                    type='text'
                    className='form-control rounded-0'
                    aria-describedby='emailHelp'
                    placeholder='Enter Name...'
                    onChange={e=>setName(e.target.value)}
                  />
                </div>
                <div className='form-group'>
                  <input
                    type='email'
                    className='form-control rounded-0'
                    aria-describedby='emailHelp'
                    placeholder='Enter email...'
                    onChange={e=>setEmail(e.target.value)}
                   
                  />
                </div>
                <div className='form-group'>
                  <textarea
                    className='form-control rounded-0'
                    rows='5'
                    placeholder='Enter Message...'
                    onChange={e=>setMessage(e.target.value)}
                  />
                </div>
                <div className='form-group text-center'>
                  <button className='btn btn-block btn-primary rounded-0 mr-auto ml-auto' onClick={() =>apicall({name},{email},{message})}>
                    Send
                  </button>
                </div>
              {/* </form> */}
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Contact;
