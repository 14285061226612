import React from 'react';
import Section from '../../../HOC/Section';

const mapMarker = () => {
  return (
    <Section id='map'>
      <div>
        <iframe
          title='mapMarker'
          src='https://maps.google.com/maps?output=embed&q=8.764166, 78.134834 &z=15&output=embed" width="360" height="270" frameborder="0" style="border:0"'
          width='100%'
          height='450'
          frameBorder='0'
          style={{ border: 0, marginBottom: '-7px' }}
          allowFullScreen
        />
        {/* //  src='https://maps.google.com/maps?q=8.764166, 78.134834 &z=15&output=embed" width="360" height="270" frameborder="0" style="border:0"' */}
      </div>
    </Section>
  );
};

export default mapMarker;
