import Modal from 'react-bootstrap/Modal'

import React from 'react';
export default function messagesubmit(name,email,message)
{ 
//  Working API
 /* var http = new XMLHttpRequest();
  var url = 'https://tiaraintegration.com/ZohoPayWay/Payway';
  const encodemsg = `${encodeURIComponent(message.message)}`
 
  var params = 'url=https://www.zohoapis.com/crm/v2/functions/ticket_create/actions/execute?auth_type=apikey&zapikey=1003.9145fb5d691b5f95a194c17e56300ed3.1cc7246813d8d6842c47f543a4f50b8f&name='+encodeURIComponent(name.name)+'&email='+encodeURIComponent(email.email)+'&msg='+encodeURIComponent(encodemsg);
  http.open('POST', url, true);
  
  //Send the proper header information along with the request
  http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
  //http.setRequestHeader('Access-Control-Allow-Origin', 'http://localhost:8080');
  http.onreadystatechange = function() {//Call a function when the state changes.
      if(http.readyState == 4 && http.status == 200) {
          alert("Thank You!! We will contact you soon");
          
     }
 }
 http.send(params);
 */
 alert("There is some error in processing your Request. Try again later!!");
}
